import React from 'react';

import Layout from '../../components/layout/Layout';
import Seo from '../../components/Seo';
import SplitSection from '../../components/SplitSection';
import Callout from '../../components/Callout';
import CalloutSimple from '../../components/CalloutSimple';

import switchoff from '../../assets/drawings/switchoff.png';
import controlpannel from '../../assets/drawings/controlpannel.png';
import costplanning from '../../assets/drawings/costplanning.png';

export default () => {
  const title = 'Cost Limitation and Visibility';
  const description =
    'Strio gives you more control over your infrastructure spendings, allowing you to set a predefined budget, visualize your costs and switch off environments whenever you want.';
  const url = 'https://strio.app/usecases/cost';

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />

      <div className="bg-lownight md:h-20 h-16" />
      <section className="relative pa-0 overflow-hidden">
        <div className="squared-background-left" />
        <div className="squared-background-right" />

        <div className="flex items-center relative z-1 lg:my-40 mt-32">
          <div className="container py-6 px-4">
            <div className="text-center md:text-left text-lownight">
              <h1 className="lg:mt-10 text-4xl lg:text-5xl xl:text-6xl font-bold leading-none tracking-tight">
                Cost limitation and visibility
              </h1>
              <p className="font-normal leading-snug text-base lg:text-2xl mt-12">
                With Strio, manage your cloud costs like never before. While cloud resources can be
                sometimes difficult to understand and plan, Strio gives you more control over your
                infrastructure spendings, allowing you to set a predefined budget, visualize your
                costs and switch off environments whenever you want.
              </p>
              <Callout />
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="bg-white shadow-inner">
        <SplitSection
          reverseOrder
          primarySlot={
            <img
              src={switchoff}
              style={{ height: '200px' }}
              alt="Switch off button"
              className="mx-auto my-8"
            />
          }
          secondarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Automatically switch off environments
              </h3>
              <div className="mt-6 text-lg leading-relaxed text-lownight">
                No more forgotten running environments that cost you a lot more than expected at the
                end of the month! With Strio, your test environment is destroyed once it has been
                used. You can either set a timer or switch the environment off automatically once
                the branch or Pull Request is merged.
              </div>
            </div>
          }
        />
        <SplitSection
          primarySlot={
            <img
              src={controlpannel}
              style={{ height: '200px' }}
              alt="Control pannel"
              className="mx-auto my-8"
            />
          }
          secondarySlot={
            <div className="lg:pl-32 xl:pl-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Monitor your infrastructure spendings
              </h3>
              <div className="mt-6 text-lg leading-relaxed">
                Finally understand and plan your infrastructure spendings. Get access to a dashboard
                that lets you visualize what you are spending on. Strio tags resources
                automatically, or lets you tag them manually.
              </div>
            </div>
          }
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <img
              src={costplanning}
              style={{ height: '200px' }}
              alt="Cost planning icon"
              className="mx-auto my-8"
            />
          }
          secondarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Cost simulation close to reality
              </h3>
              <div className="mt-6 text-lg leading-relaxed text-lownight">
                Plan the cost of a POC or a new project and set a limit for your monthly
                infrastructure budget by team or by project, so you can be sure that you will not
                receive a crazy bill.
              </div>
            </div>
          }
        />
      </section>
      <section className="bg-gray-100">
        <CalloutSimple />
      </section>
    </Layout>
  );
};
